<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        At 565 K, the gas phase reaction shown below has an equilibrium constant
        <stemble-latex content="$\text{K}_\text{eq}=6.04\times10^9.$" />
      </p>

      <p class="mb-4 pl-8">
        <chemical-latex content="N2(g) + O2(g) <=> 2NO(g)" />
      </p>

      <p class="mb-3">
        Assuming
        <number-value :value="PNO" unit="\text{bar}" />
        of NO gas is placed in a sealed container at 565 K, answer each of the questions below.
      </p>

      <p class="mb-n3">a) What direction will the reaction proceed?</p>

      <v-radio-group v-model="inputs.rxnDirection" :disabled="!allowEditing" class="ml-6 mb-0">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        b) Determine the partial pressure of
        <chemical-latex content="O2" />
        once the system reaches equilibrium.
      </p>

      <calculation-input
        v-model="inputs.PO2eq"
        class="mb-0"
        prepend-text="$\text{P}_{\text{O}_2}:$"
        append-text="$\text{bar}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question462',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        rxnDirection: null,
        PO2eq: null,
      },
      options: [
        {expression: 'Forwards', value: 'forwards'},
        {expression: 'Backwards', value: 'backwards'},
      ],
    };
  },
  computed: {
    PNO() {
      return this.taskState.getValueBySymbol('PNO').content;
    },
  },
};
</script>
